import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@x/common/loader/loader.module';
import { RoutingModule } from '@x/ecommerce-shop/app/core/routing/routing.module';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageMetaComponent } from './page-meta/page-meta.component';
import { PagePanelComponent } from './page-panel/page-panel.component';
import { PageRowComponent } from './page-row/page-row.component';

@NgModule({
  declarations: [PageLayoutComponent, PageMetaComponent, PagePanelComponent, PageRowComponent],
  imports: [CommonModule, LoaderModule, RoutingModule],
  exports: [PageLayoutComponent, PageMetaComponent, PagePanelComponent, PageRowComponent],
})
export class PageLayoutModule {}
