import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { loaderSize } from '@x/common/loader/loader.component';

@Component({
  selector: 'x-page-panel',
  templateUrl: './page-panel.component.html',
  styleUrls: ['./page-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-page-panel' },
})
export class PagePanelComponent {
  @HostBinding('class')
  @Input()
  class: string;

  @Input() headingAlignment: 'left' | 'right' | 'center' = 'left';
  @Input() headingSize: 'large' | 'medium' | 'normal' | 'small' = 'normal';
  @Input() loadingSize: loaderSize = 'default';

  @Input()
  set panelLoading(loading: any) {
    this.loading = coerceBooleanProperty(loading);
  }
  loading: boolean = false;

  @Input()
  heading: string | undefined;

  @Input()
  subHeading: string;
}
