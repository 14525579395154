import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';

@Directive({
  selector: '[xRedirectBack]',
})
export class RedirectBackDirective {
  @HostBinding('attr.rel')
  rel: string;

  @HostBinding('class')
  class: string = 'cursor-pointer';

  @Input() canRedirect?: Observable<boolean>;

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    let { redirect, ...queryParams } = this.route.snapshot.queryParams;
    const url = redirect?.split('?')[0] ?? '/';

    if (this.canRedirect) {
      const _canRedirect = await firstValueFrom(this.canRedirect);
      if (_canRedirect) await this.router.navigate([url], { queryParams });

      return;
    }

    await this.router.navigate([url], { queryParams });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}
}
