import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IfRouteTransitionDirective } from '@x/ecommerce-shop/app/core/routing/directives/if-route-transition.directive';
import { RedirectBackDirective } from '@x/ecommerce-shop/app/core/routing/directives/redirect-back.directive';
import { GoHomeLinkDirective } from './directives/go-home-link.directive';
import { NavigateWithRedirectDirective } from './directives/navigate-with-redirect.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    IfRouteTransitionDirective,
    RedirectBackDirective,
    GoHomeLinkDirective,
    NavigateWithRedirectDirective,
  ],
  exports: [
    IfRouteTransitionDirective,
    RedirectBackDirective,
    GoHomeLinkDirective,
    NavigateWithRedirectDirective,
  ],
})
export class RoutingModule {}
