import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[xNavigateWithRedirect]',
})
export class NavigateWithRedirectDirective {
  @HostListener('click')
  async onClick() {
    if (!this.url) throw new Error('url is required');

    const redirect = decodeURIComponent(this.router.url.split('?')[0]);

    await this.router.navigate([this.url], {
      queryParams: { redirect },
      queryParamsHandling: 'merge',
    });
  }

  @Input() url: string;

  @HostBinding('class')
  class: string = 'cursor-pointer';

  constructor(private router: Router) {}
}
