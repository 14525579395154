<ng-container *ngIf="heading">
  <div class="actions-top">
    <ng-content select="[xActionTop]"></ng-content>
  </div>

  <header class="mb-4" [class]="'header-align-' + headingAlignment">
    <div class="action-left">
      <ng-content select="[xActionLeft]"></ng-content>
    </div>
    <div class="heading-text">
      <h1 *ngIf="headingSize === 'large'" class="heading">{{ heading }}</h1>
      <h2 *ngIf="headingSize === 'medium'" class="heading">{{ heading }}</h2>
      <h3 *ngIf="headingSize === 'normal'" class="heading">{{ heading }}</h3>
      <h5 *ngIf="headingSize === 'small'" class="heading">{{ heading }}</h5>
      <div class="sub-heading mt-2" *ngIf="subHeading">
        {{ subHeading }}
      </div>
    </div>
    <div class="action-right">
      <ng-content select="[xActionRight]"></ng-content>
    </div>
  </header>
</ng-container>

<ng-content></ng-content>

<div class="footer-actions mt-3">
  <ng-content select="[xActionFooter]"></ng-content>
</div>

<x-loader *ngIf="loading" [size]="loadingSize"></x-loader>
