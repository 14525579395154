import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { RoutingContextService } from '@x/ecommerce-shop/app/core/routing/routing-context.service';

@Directive({ selector: '[xGoHomeLink]' })
export class GoHomeLinkDirective {
  @HostListener('click')
  onClick() {
    this.rc.goHome(this.queryParamsHandling);
  }

  @Input()
  queryParamsHandling: QueryParamsHandling;

  @HostBinding('class')
  class: string = 'cursor-pointer';

  constructor(private rc: RoutingContextService) {}
}
